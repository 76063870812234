import React, { useEffect, useState, useRef, Suspense } from 'react';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight, faDiceD20,
    faEnvelope,
    faExclamationCircle, faGamepad,
    faImage,
    faLightbulb,
    faList,
    faSlidersH,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { faGlobe, faSearchLocation, faMobileAlt, faFlag, faQuestion, faComments } from '@fortawesome/free-solid-svg-icons';

import { faDiscord, faGithub, faRedditAlien } from "@fortawesome/free-brands-svg-icons";
import PieChart from './PieChart';
import PieChart2 from "./PieChart2";
import { faTable, faExpand, faWandSparkles, faMap, faQuestionCircle, faUsers, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import AboutSubpagesList from './AboutSubpagesList';
import { useDarkMode } from './DarkModeContext';
const LazyFeedbackDialog = React.lazy(() => import('./FeedbackDialog'));

const stats = [
    { id: 1, name: 'Countries covered', value: 75, suffix: '+' },
    { id: 2, name: 'Graphics', value: 500, suffix: '+' },
    { id: 3, name: 'Datasets', value: 21, suffix: '' },
    { id: 4, name: 'Quizzes', value: 12, suffix: '' },
    { id: 5, name: 'Twitch quizzes', value: 7, suffix: '' },
];

const projectHighlights = [
    { id: 7, name: 'Website visits <span style="display:block; margin-top:-4px;">worldwide</span>', value: 1000, suffix: '+' },
    { id: 8, name: 'Solve feature <span style="display:block; margin-top:-4px;">used</span>', value: 100, suffix: '+' },
    { id: 9, name: 'Data issue reports <span style="display:block; margin-top:-4px;">& contributions</span>', value: 10, suffix: '' },
    { id: 10, name: 'Classic quizzes <span style="display:block; margin-top:-4px;">completed</span>', value: 10, suffix: '' },
    { id: 11, name: 'Twitch quizzes <span style="display:block; margin-top:-4px;">completed</span>', value: 10, suffix: '' },
];

const images = [
    '/pro/1.webp',
    '/pro/m.webp',
    '/pro/2.webp',
    '/pro/3.webp',
    '/pro/4.webp',
    '/pro/5.webp',
    '/pro/6.webp',
    '/pro/7.webp',
    '/pro/8.webp',
    '/pro/10.webp',
];


const About: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMediumScreen, setIsMediumScreen] = useState(false); // For medium screens like tablets
    const [autoSlideActive, setAutoSlideActive] = useState(true);
    const { isDarkMode } = useDarkMode();
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Small screens
            setIsMediumScreen(window.innerWidth > 768 && window.innerWidth <= 1024); // Medium screens
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Auto-slide logic with a timeout
    useEffect(() => {
        if (!autoSlideActive) return;
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === images.length - (isSmallScreen || isMediumScreen ? 1 : 2) ? 0 : prevSlide + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, [autoSlideActive, isSmallScreen, isMediumScreen]);

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === images.length - 1 ? 0 : prevSlide + 1
        );
        setAutoSlideActive(false);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? images.length - (isSmallScreen || isMediumScreen ? 1 : 2) : prevSlide - 1
        );
        setAutoSlideActive(false);
    };

    const goToSlide = (index: number) => {
        setCurrentSlide(index);
        setAutoSlideActive(false);
    };



    const location = useLocation();
    const statusRef = useRef<HTMLDivElement | null>(null);
    const highlightsRef = useRef<HTMLDivElement | null>(null);

    const [isPieChartVisible, setIsPieChartVisible] = useState(false);
    const [isPieChart2Visible, setIsPieChart2Visible] = useState(false);

    const pieChartRef = useRef<HTMLDivElement | null>(null);
    const pieChart2Ref = useRef<HTMLDivElement | null>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState<'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other'>('Other');

    const [isSubpagesDialogOpen, setIsSubpagesDialogOpen] = useState(false);

    useEffect(() => {
        if (location.hash === "#status" && statusRef.current) {
            statusRef.current.scrollIntoView({ behavior: "auto" });
        } else if (location.hash === "#highlights" && highlightsRef.current) {
            highlightsRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [location]);

    useEffect(() => {
        document.title = "About | GeographyHelp";

        const observerOptions = { threshold: 0.5 };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === pieChartRef.current) {
                        setIsPieChartVisible(true);
                    } else if (entry.target === pieChart2Ref.current) {
                        setIsPieChart2Visible(true);
                    }
                }
            });
        }, observerOptions);

        if (pieChartRef.current) observer.observe(pieChartRef.current);
        if (pieChart2Ref.current) observer.observe(pieChart2Ref.current);

        return () => {
            if (pieChartRef.current) observer.unobserve(pieChartRef.current);
            if (pieChart2Ref.current) observer.unobserve(pieChart2Ref.current);
        };
    }, []);

    const startCounting = (id: number) => {
        const stat = stats.find(stat => stat.id === id);
        const highlight = projectHighlights.find(highlight => highlight.id === id);
        const endValue = stat?.value || highlight?.value || 0;

        let start = 0;
        const duration = 2000;
        const stepTime = Math.abs(Math.floor(duration / endValue));

        const timer = setInterval(() => {
            start += 1;
            setCounters((prev) => ({
                ...prev,
                [id]: start,
            }));

            if (start >= endValue) {
                clearInterval(timer);
                setIsComplete((prev) => ({
                    ...prev,
                    [id]: true,
                }));
            }
        }, stepTime);
    };

    const [counters, setCounters] = useState<{ [key: number]: number }>({});
    const [isComplete, setIsComplete] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const id = entry.target.getAttribute("data-id");
                    if (id) {
                        startCounting(parseInt(id, 10));
                    }
                }
            });
        }, { threshold: 0.5 });

        document.querySelectorAll('.counter').forEach(el => observer.observe(el));

        return () => observer.disconnect();
    }, []);

    const renderCounter = (id: number) => {
        const stat = stats.find(stat => stat.id === id);
        const highlight = projectHighlights.find(highlight => highlight.id === id);
        const value = stat?.value || highlight?.value || 0;
        const suffix = stat?.suffix || highlight?.suffix || '';

        return (
            <>
                {counters[id] || 0}
                {isComplete[id] && suffix}
            </>
        );
    };

    const handleOpenSubpagesDialog = () => {
        setIsSubpagesDialogOpen(true);
    };

    const handleCloseSubpagesDialog = () => {
        setIsSubpagesDialogOpen(false);
    };

    const handleOpenDialog = (type: 'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other') => {
        setFeedbackType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div className="bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 pt-5 pb-5">

            <div className="mx-auto max-w-7xl px-4 lg:px-8">
                <div className="text-center mb-8">
                    <h2 className="text-3xl sm:text-5xl font-inter font-extrabold tracking-tight bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                        All-in-one Hub for
                        <span className="block sm:inline"> GeoGuessr Resources</span>
                    </h2>


                    <div className=" mx-auto max-w-4xl">
                        <p className="mt-4 text-md sm:text-xl font-inter leading-7 text-gray-600 dark:text-gray-400">
                            Navigate a rich collection of country-specific data, graphics, interactive maps, quizzes, and the Solve tool to pinpoint countries from in-game clues. Elevate your geography skills today!
                        </p>
                    </div>
                </div>

                <div className="mx-auto max-w-full md:max-w-4xl">
                    <dl className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-x-2 gap-y-8 sm:gap-y-12 text-center lg:gap-x-4">
                        {/* Check if the screen is small, and slice accordingly */}
                        {(isSmallScreen ? stats.slice(0, 4) : stats).map((stat) => (
                            <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-1 sm:gap-y-2 counter" data-id={stat.id}>
                                <dd className="order-first text-3xl font-inter font-semibold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl">
                                    {renderCounter(stat.id)}
                                </dd>
                                <dt className="text-base leading-7 font-inter text-gray-600 dark:text-gray-400">{stat.name}</dt>
                            </div>
                        ))}
                    </dl>
                </div>


                <div className="flex justify-center items-center space-x-3 sm:space-x-6 mt-8">
                    <a
                        href="https://discord.gg/7r3XnkFACB"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hidden md:flex bg-black h-[40px] sm:h-[50px] dark:bg-white dark:hover:bg-transparent dark:hover:text-white text-white dark:text-[#894bed] hover:bg-white hover:text-black py-1 pl-2 pr-2 rounded-[10px] transition-colors duration-300 text-sm md:text-base border-[1px] border-solid border-black dark:border-[#737af4] items-center justify-between group"
                    >
                        <div className="flex items-center space-x-1.5">
                            <FontAwesomeIcon icon={faDiscord} className="text-2xl mt-[-3px]"/>
                            <div className="flex flex-col items-start">
                <span className="text-[13px] sm:text-[16px] font-inter sm:mt-[-1px]">
                    Join Discord server
                </span>
                                <p className="text-[8px] sm:text-[11px] font-inter mt-[-5px] sm:mt-[-7px] transition-colors text-[#B1B7C3] dark:text-[#6c88f6] group-hover:text-[#424C5A] dark:group-hover:text-[#B1B7C3]">
                                    Join our community & get updates
                                </p>
                            </div>
                        </div>
                    </a>

                    <a href="https://www.producthunt.com/posts/geographyhelp?embed=true&utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-geographyhelp"
                       target="_blank">
                        <img
                            src={isDarkMode
                                ? "https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=489425&theme=light&period=weekly&topic_id=204"
                                : "https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=489425&theme=dark&period=weekly&topic_id=204"}
                            alt="GeographyHelp - Comprehensive GeoGuessr Resources with maps, quizzes, and more | Product Hunt"
                            className="h-[40px] sm:h-[50px] w-auto"
                        />
                    </a>

                    <a href="https://www.producthunt.com/posts/geographyhelp?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-geographyhelp"
                       target="_blank">
                        <img
                            src={isDarkMode
                                ? "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=489425&theme=light"
                                : "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=489425&theme=dark"}
                            alt="GeographyHelp - GeoGuessr Resource Platform with maps, quizzes and more | Product Hunt"
                            className="h-[40px] sm:h-[50px] w-auto"
                        />
                    </a>
                </div>
            </div>
            {/* --- Carousel container --- */}
            <div className="w-full px-4">
                <div className="relative my-5 max-w-[1600px] mx-auto">
                    <div className="flex items-center justify-center" role="region" aria-label="Image carousel">
                        {!isSmallScreen && (
                            <button
                                className="p-2 bg-transparent border-none cursor-pointer"
                                aria-label="Previous slide"
                                onClick={handlePrevSlide}
                            >
                                <span aria-hidden="true">
                                    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-current text-gray-400">
                                        <path d="M16.41 5.41L15 4l-8 8 8 8 1.41-1.41L9.83 12"></path>
                                    </svg>
                                </span>
                            </button>
                        )}

                        <div className="overflow-hidden w-full lg:max-w-[1600px] mx-auto">
                            <div
                                className="flex transition-transform duration-500 ease-in-out"
                                style={{
                                    transform: `translateX(${-currentSlide * 100 / (isSmallScreen || isMediumScreen ? 1 : 2)}%)`,
                                }} // Change the number of slides based on screen size
                            >
                                {images.map((image, index) => (
                                    <div
                                        className={`flex-none ${isSmallScreen || isMediumScreen ? 'w-full' : 'w-1/2'} p-2`}
                                        key={index}
                                    >
                                        <img
                                            src={image}
                                            className="w-full h-auto rounded-lg object-cover"
                                            style={{ maxHeight: '750px' }}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!isSmallScreen && (
                            <button
                                className="p-2 bg-transparent border-none cursor-pointer"
                                aria-label="Next slide"
                                onClick={handleNextSlide}
                            >
                                <span aria-hidden="true">
                                    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-current text-gray-400">
                                        <path d="M7.59 18.59L9 20l8-8-8-8-1.41 1.41L14.17 12"></path>
                                    </svg>
                                </span>
                            </button>
                        )}
                    </div>

                    {/* Carousel navigation (thumbnails) */}
                    <div className="flex justify-center mt-4 flex-wrap gap-x-2 gap-y-2"> {/* Added gap-x-2 and gap-y-2 */}
                        {/* Render all thumbnails at once */}
                        {images.map((image, index) => (
                            <button
                                key={index}
                                onClick={() => goToSlide(index)}
                                aria-label={`Go to slide ${index + 1}`}
                                className={`p-1 ${currentSlide === index ? 'ring-2 ring-blue-500 rounded-lg' : ''}`}
                            >
                                <img
                                    src={image}
                                    className="w-14 h-8 sm:w-20 sm:h-12 md:w-24 md:h-14 object-cover rounded-lg transition-all duration-300" // Adjust size accordingly
                                    alt={`Slide ${index + 1}`}
                                />
                            </button>
                        ))}
                    </div>



                </div>
            </div>

            <div className="mx-auto max-w-screen-xl px-4 lg:px-8 ">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 w-full mb-12 mt-16 font-inter">
                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faGlobe} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>All-in-One Hub<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Access everything from the resource grid, maps, quizzes & Solve feature in one place.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faTable} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Interactive Resource Grid<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                A flexible table with customizable options, allowing search, grouping, filtering & more.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faMap} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Maps<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Navigate interactive maps with detailed datasets, zoom and customizable themes.
                </span>
                        </div>
                    </div>
                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faFlag} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Quiz: Identify the Country<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Test your GeoGuessr knowledge by identifying the country based on clues.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faQuestion} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Quiz: Identify the Clue<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Select the correct clues from the panel, that match the currently highlighted country.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faTwitch} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Twitch Chat Integrated Quizzes<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Engage your community with Twitch quizzes that allow chatters to participate.
                </span>
                        </div>
                    </div>
                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faSearchLocation} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Solve<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Identify countries during GeoGuessr games by selecting clues from Street View images.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Mobile Optimized<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Access quizzes, maps, and tools on the go with a fully mobile-optimized interface.
                </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[40px,1fr] items-start mt-[-5px]">
                        <div className="flex justify-center items-start mt-[6px]">
                            <FontAwesomeIcon icon={faDatabase} className="text-2xl" />
                        </div>
                        <div className="ml-[8px] self-start">
                            <b>Continuously Improved Database<span className="md:hidden">: </span></b><br className="hidden md:block" />
                            <span className="text-gray-600 dark:text-gray-400">
                Open to community reports for adding new data or correcting incorrect data.
                </span>
                        </div>
                    </div>
                </div>
            </div>



            {/* --- Second container for other content below the carousel --- */}
            <div className="mx-auto max-w-[1600px] px-4 lg:px-8"> {/* Adjusted for full width */}

                {/* Development Status and Resource Contributions in two columns */}
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 pt-5"> {/* Grid layout for two columns */}

                    <div  ref={statusRef} id="status" className=" pt-16 mx-6 md:mx-auto md:max-w-[613px]  text-center ">
                    <h2 className="text-3xl pb-1 sm:text-5xl font-inter font-extrabold tracking-tight bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                        Development Status
                    </h2>
                    <div className="flex flex-col md:flex-row md:gap-8 gap-0 mt-6">
                        {/* First Column */}
                        <ul className="space-y-2 min-w-[200px] text-left">
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faTable} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Home: resource table</span>
                                <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-500/10 px-2 py-1 text-xs font-bold font-inter text-green-700 dark:text-green-400 ring-1 ring-green-500/30 dark:ring-green-400/20">
                Online
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faSlidersH} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Home: customisation</span>
                                <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-500/10 px-2 py-1 text-xs font-bold font-inter text-green-700 dark:text-green-400 ring-1 ring-green-500/30 dark:ring-green-400/20">
                Online
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faMap} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Mapped resources</span>
                                <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-500/10 px-2 py-1 text-xs font-bold font-inter text-green-700 dark:text-green-400 ring-1 ring-green-500/30 dark:ring-green-400/20">
                21 Online
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faImage} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Scenery visual data</span>
                                <span className="inline-flex items-center rounded-md bg-gray-50 dark:bg-gray-400/10 px-2 py-1 text-xs font-bold font-inter text-gray-600 dark:text-gray-400 ring-1 ring-gray-500/30 dark:ring-gray-400/20">
                Planned
            </span>
                            </li>

                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faWandSparkles} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Solve</span>
                                <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-400/10 px-2 py-1 text-xs font-bold font-inter text-yellow-800 dark:text-yellow-500 ring-1 ring-yellow-600/30 dark:ring-yellow-500/20">
                Ongoing precision updates
                                </span>
                            </li>


                        </ul>

                        {/* Second Column */}
                        <ul className="space-y-2 min-w-[200px] mt-2 md:mt-0 text-left">
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faQuestionCircle} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Classic single-player quizzes</span>
                                <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-500/10 px-2 py-1 text-xs font-bold font-inter text-green-700 dark:text-green-400 ring-1 ring-green-500/30 dark:ring-green-400/20">
                12 Online
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faTwitch} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Twitch chat integrated quizzes</span>
                                <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-500/10 px-2 py-1 text-xs font-bold font-inter text-green-700 dark:text-green-400 ring-1 ring-green-500/30 dark:ring-green-400/20">
                7 Online
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faExpand} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Home: expand country section</span>
                                <span className="inline-flex items-center rounded-md bg-gray-50 dark:bg-gray-400/10 px-2 py-1 text-xs font-bold font-inter text-gray-600 dark:text-gray-400 ring-1 ring-gray-500/30 dark:ring-gray-400/20">
                Planned
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faDatabase} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Resource database</span>
                                <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-400/10 px-2 py-1 text-xs font-bold font-inter text-yellow-800 dark:text-yellow-500 ring-1 ring-yellow-600/30 dark:ring-yellow-500/20">
                Continuously improving
            </span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <FontAwesomeIcon icon={faUsers} className="text-gray-900 dark:text-gray-100" />
                                <span className="font-inter text-gray-600 dark:text-gray-400">Multiplayer quizzes</span>
                                <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-400/10 px-2 py-1 text-xs font-bold font-inter text-red-700 dark:text-red-400 ring-1 ring-red-500/30 dark:ring-red-400/20">
                Currently not available
            </span>
                            </li>

                        </ul>
                    </div>
                    <div className="mt-5">
                        <button
                            onClick={handleOpenSubpagesDialog} // Open the subpages dialog
                            className="group inline-flex items-center justify-center space-x-2 text-lg  font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors"
                        >
                            <FontAwesomeIcon icon={faList} className="text-black dark:text-white"/>
                            <span>View Available Subpages</span>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="transform transition-transform duration-300 group-hover:translate-x-2"
                            />
                        </button>
                    </div>
                </div>
                    <div className=" mx-6 lg:mx-auto lg:max-w-[613px]  mt-16">
                        {/* Centered Header and Paragraph */}
                        <div className="text-center">
                            <h2 className="text-3xl pb-1 sm:text-5xl font-inter font-extrabold tracking-tight bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                                Data Contributions
                            </h2>
                            <div className="mx-auto max-w-2xl mt-5">
                                <p className=" text-md font-inter leading-7 text-gray-600 dark:text-gray-400">
                                    Help improve this app with better data & images to make it more comprehensive.

                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6"> {/* 2 columns layout with gap */}
                            {/* First Column - Left-aligned Buttons Section */}
                            <div className="flex flex-col space-y-1 mt-3">
                                <Link to="https://discord.gg/7r3XnkFACB">
                                    <a className="group inline-flex items-center justify-start space-x-2 text-lg font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors">
                                        <FontAwesomeIcon icon={faDiscord} className="text-black dark:text-white text-sm" />
                                        <span>Join Discord server</span>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="transform -rotate-45 transition-transform duration-300 group-hover:translate-x-1.5 group-hover:-translate-y-1.5"
                                        />
                                    </a>
                                </Link>




                                <button
                                    onClick={() => handleOpenDialog('Contribute new data')}
                                    className="group inline-flex items-center justify-start space-x-2 text-lg font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors"
                                >
                                    <FontAwesomeIcon icon={faGithub} className="text-black dark:text-white" />
                                    <span>Contribute new data</span>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="transform transition-transform duration-300 group-hover:translate-x-2"
                                    />
                                </button>

                                <button
                                    onClick={() => handleOpenDialog('Report data issue')}
                                    className="group inline-flex items-center justify-start space-x-2 text-lg font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors"
                                >
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-black dark:text-white" />
                                    <span>Report incorrect data</span>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="transform transition-transform duration-300 group-hover:translate-x-2"
                                    />
                                </button>

                                <button
                                    onClick={() => handleOpenDialog('Request new feature')}
                                    className="group inline-flex items-center justify-start space-x-2 text-lg font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors"
                                >
                                    <FontAwesomeIcon icon={faLightbulb} className="text-black dark:text-white mr-0.5 ml-0.5" />
                                    <span>Request new feature</span>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="transform transition-transform duration-300 group-hover:translate-x-2"
                                    />
                                </button>

                                <button
                                    onClick={() => handleOpenDialog('Other')}
                                    className="group inline-flex items-center justify-start space-x-2 text-lg font-inter text-gray-600 dark:text-gray-400 hover:text-black dark:hover:text-white transition-colors"
                                >
                                    <FontAwesomeIcon icon={faEnvelope} className="text-black dark:text-white" />
                                    <span>Share your thoughts</span>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="transform transition-transform duration-300 group-hover:translate-x-2"
                                    />
                                </button>
                            </div>

                            <div className="w-auto text-left pt-4">
                                <ul className="space-y-4"> {/* Add space between each contribution */}
                                    <li className="relative pl-4 text-xs sm:text-sm font-inter text-gray-600 dark:text-gray-400">
                                        <div className="absolute left-0 top-[4px] bottom-[4px] border-l-2 border-gray-400"></div> {/* Adjust vertical border */}
                                        <div className="inline-flex items-center">
                                            <a
                                                href="https://www.reddit.com/user/_algorerhythm/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center font-bold hover:text-gray-900 dark:hover:text-gray-100 transition-colors"
                                            >
                                                <FontAwesomeIcon icon={faRedditAlien} className="text-sm sm:text-md mr-1.5" />
                                                u/_algorerhythm
                                            </a>
                                            <span className="ml-2">contributed</span>
                                        </div>
                                        <a
                                            href="https://docs.google.com/spreadsheets/d/1YBSyAkpRBHN2fuWW4IpjQb2usfcW_Aic4uZhkZYJBWk/htmlview#gid=0"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex font-inter text-xs sm:text-sm items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 transition-colors"
                                        >
                                            <FontAwesomeIcon icon={faTable} className="text-sm sm:text-md mr-1.5" />
                                            Street View coverage spreadsheet
                                        </a>
                                    </li>
                                </ul>
                            </div>



                        </div>

                    </div>

                </div>
            </div>
            <div className="mx-auto max-w-[1600px] px-4 lg:px-8 py-10 ">
                {/* Two Columns: Pie Charts on the Left, Other Content on the Right */}
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 pt-2 lg:pt-8">

                    {/* Left Column: Pie Charts */}
                    <div className="pt-6 mx-6 pb-6 text-center">
                        <h2 className="text-3xl pb-1 sm:text-5xl font-inter font-extrabold tracking-tight bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                            Quiz Attempt Stats
                        </h2>
                        <div className="flex flex-row justify-center gap-8 mt-4">
                            <div className="transform scale-[0.8]">
                                <div ref={pieChartRef}>
                                    {isPieChartVisible && <PieChart />}
                                </div>
                            </div>
                            <div className="transform scale-[0.8]">
                                <div ref={pieChart2Ref}>
                                    {isPieChart2Visible && <PieChart2 />}
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Right Column: Other Content */}
                    <div className="pt-8  mx-6 md:mx-auto md:max-w-[613px]  text-center">
                        {/* Header Section with GeographyHelp logo and adjusted font */}
                        <div className="flex items-center pb-1 justify-center">
                            <img src="/g.webp" alt="Geography Icon" className="w-[40px] h-[40px] mr-3" />
                            <h1 className="text-2xl  sm:text-4xl font-inter font-extrabold tracking-tight bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                                Geography<span className="font-thin">Help</span>
                            </h1>
                        </div>

                        {/* Coffee Support Section */}
                        <div className="text-md font-inter leading-7 text-gray-600 dark:text-gray-400 mt-6">
                            If you enjoy the app, consider buying me a coffee. Your support keeps it thriving!
                        </div>
                        <div className="flex justify-center gap-x-4 gap-y-2 flex-wrap mt-4">
                            <a
                                href="https://buymeacoffee.com/geographyhelp"
                                className="bg-white h-[34px] text-black hover:bg-yellow-500 hover:text-gray-300 font-bold rounded-full px-5 transition-all duration-700 text-sm md:text-base border-3 border-solid border-yellow-500 md:border-3 border-2 flex items-center justify-center transform scale-100 hover:scale-110"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/bmc.svg" alt="Buy me a coffee" className="w-[120px] h-auto" />
                            </a>

                            <span className="block w-full sm:hidden"></span>

                            <a
                                href="https://www.twitch.tv/directory/game/GeographyHelp"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-black h-[34px] dark:bg-white dark:hover:bg-transparent dark:hover:text-white text-white dark:text-black hover:bg-white hover:text-black py-1 px-1.5 sm:px-3 rounded-full transition-colors duration-300 border-3 border-solid border-black dark:border-white md:border-3 border-2 flex items-center"
                            >
                                <FontAwesomeIcon icon={faTwitch} className="text-[15px] mr-1 sm:mr-1.5" />
                                <span className="text-[12px] sm:text-[13px] font-inter tracking-tight whitespace-nowrap">Twitch Game Category</span>
                            </a>

                            <a
                                href="https://www.igdb.com/games/geographyhelp"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-black h-[34px] dark:bg-white dark:hover:bg-transparent dark:hover:text-white text-white dark:text-black hover:bg-white hover:text-black py-1 px-1.5 sm:px-3 rounded-full transition-colors duration-300 border-3 border-solid border-black dark:border-white md:border-3 border-2 flex items-center"
                            >
                                <FontAwesomeIcon icon={faDiceD20} className="text-[15px] mr-1 sm:mr-1.5" />
                                <span className="text-[12px] sm:text-[13px] font-inter tracking-tight whitespace-nowrap">Internet Game Database</span>
                            </a>
                        </div>




                        {/* Credits and Inspiration Section */}
                        <div className="mt-6 text-center mb-2">
                            <p className="flex justify-center items-center text-center mx-auto max-w-4xl text-[12px]  sm:text-lg font-inter leading-5 text-gray-600 dark:text-gray-400">
                                <FontAwesomeIcon icon={faDatabase} className="mr-1 sm:mr-2 text-gray-600 dark:text-gray-400" />
                                Based on our 2021 GeoGuessr Resource Spreadsheet
                            </p>
                            <p className="flex justify-center items-center text-center mx-auto max-w-4xl mt-2 text-[13px]  sm:text-lg font-inter leading-5 text-gray-600 dark:text-gray-400">
                                <FontAwesomeIcon icon={faTwitch} className="mr-1 sm:mr-2 text-gray-600 dark:text-gray-400" />
                                Twitch quizzes feature inspired by&nbsp;
                                <a href="https://chatguessr.com/play" target="_blank" rel="noopener noreferrer" className="font-bold hover:text-black dark:hover:text-white">ChatGuessr</a>
                            </p>
                        </div>

                        <AboutSubpagesList isOpen={isSubpagesDialogOpen} onClose={handleCloseSubpagesDialog} />

                        {dialogOpen && (
                            <div style={{ height: '0px', overflow: 'hidden' }}>
                                <Suspense fallback={<div style={{ height: '0px' }}></div>}>
                                    <LazyFeedbackDialog isOpen={dialogOpen} onClose={handleCloseDialog} selectedFeedbackType={feedbackType} />
                                </Suspense>
                            </div>
                        )}
                    </div>

                </div>
            </div>

        </div>
    );
};

export default About;
